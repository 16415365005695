import { useHearingAidsConnectionStatus } from "./useHearingAidConnectionStatus";
import {
    HearingAids,
    useAssignedHearingAidsSelector,
} from "../../BoundedContext/PatientProfile/hooks/hearingAids/useAssignedHearingAidsSelector";

interface ConnectionStatus {
    atLeastOneConnectionSimulated: boolean;
    isRightHAConnected: boolean;
    isLeftHAConnected: boolean;
    atLeastOneIsConnected: boolean;
    isLeftHAReadyForProgramming: boolean;
    isRightHAReadyForProgramming: boolean;
    isLeftHAConnectionSimulated: boolean;
    isRightHAConnectionSimulated: boolean;
    atLeastOneReadyForProgramming: boolean;
    assignedHAs: HearingAids;
}

export const useHearingAidsConnectionStatusSelector = (patientId: string): ConnectionStatus => {
    const connectedHearingAids = useAssignedHearingAidsSelector(patientId);
    const connectionStatus = useHearingAidsConnectionStatus(patientId ?? null, {
        leftHAId: connectedHearingAids.data?.left?.id ?? null,
        rightHAId: connectedHearingAids.data?.right?.id ?? null,
    });
    return {
        atLeastOneConnectionSimulated: connectionStatus.atLeastOneConnectionSimulated,
        isRightHAConnected: connectionStatus.isRightHAConnected,
        isLeftHAConnected: connectionStatus.isLeftHAConnected,
        atLeastOneIsConnected: connectionStatus.atLeastOneIsConnected,
        isLeftHAReadyForProgramming: connectionStatus.isLeftHAConnectionSimulated,
        isRightHAReadyForProgramming: connectionStatus.isRightHAReadyForProgramming,
        isLeftHAConnectionSimulated: connectionStatus.isLeftHAConnectionSimulated,
        isRightHAConnectionSimulated: connectionStatus.isRightHAConnectionSimulated,
        atLeastOneReadyForProgramming: connectionStatus.atLeastOneReadyForProgramming,
        assignedHAs: {
            left: connectedHearingAids.data?.left!,
            right: connectedHearingAids.data?.right!,
        },
    };
};
