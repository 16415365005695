/* eslint-disable indent */
import "./HIStatusIndicators.scss";
import { useParams } from "react-router-dom";
import { HearingAid } from "./HearingAid";
import { Divider } from "@wsa/echo-components/dist";
import { DetectButton } from "../DetectButton";
import { useHearingAidsConnectionStatusSelector } from "../../State/Hooks/useHearingAidsConnectionStatusSelector";
interface HIStatusIndicatorsProps {
    withDetectionButton: boolean;
}

const HIStatusIndicators = (props: HIStatusIndicatorsProps) => {
    const { patientId } = useParams();
    const {
        assignedHAs: assignedHIs,
        atLeastOneConnectionSimulated,
        atLeastOneIsConnected,
        isRightHAConnected,
        isLeftHAConnected,
    } = useHearingAidsConnectionStatusSelector(patientId!);
    const hideButton = atLeastOneIsConnected && !atLeastOneConnectionSimulated;
    if (!patientId) return null;

    return (
        <>
            <HearingAid
                earSide="right"
                hearingAid={assignedHIs.right}
                patientId={patientId}
                isConnected={isRightHAConnected && !atLeastOneConnectionSimulated}
            />
            {props.withDetectionButton && !hideButton ? (
                <div className="detection-button-container echo-dark">
                    <DetectButton />
                </div>
            ) : (
                <div className="hi-status-divider-container">
                    <Divider
                        orientation="vertical"
                        className="hi-status-divider-container__divider"
                    />
                </div>
            )}

            <HearingAid
                earSide="left"
                hearingAid={assignedHIs.left}
                patientId={patientId}
                isConnected={isLeftHAConnected && !atLeastOneConnectionSimulated}
            />
        </>
    );
};

export default HIStatusIndicators;
